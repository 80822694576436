* {
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;

  font-family: sans-serif;
}
